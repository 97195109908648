// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-archive-month-template-tsx": () => import("./../../../src/templates/archive-month-template.tsx" /* webpackChunkName: "component---src-templates-archive-month-template-tsx" */),
  "component---src-templates-archive-year-template-tsx": () => import("./../../../src/templates/archive-year-template.tsx" /* webpackChunkName: "component---src-templates-archive-year-template-tsx" */),
  "component---src-templates-blog-post-template-index-tsx": () => import("./../../../src/templates/blog-post-template/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-index-tsx" */),
  "component---src-templates-tag-page-template-tsx": () => import("./../../../src/templates/tag-page-template.tsx" /* webpackChunkName: "component---src-templates-tag-page-template-tsx" */)
}

